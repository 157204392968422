








































import { api } from "@/api/api";
import { ApiGetFacilitationGrantApplicationDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { grantApplicationStatusMap } from "@/shared/constants/grantApplicationStatusMap";
import { GrantApplicationStatus } from "@/shared/enums/GrantApplicationStatus.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "FacilitationGrantsDashboardPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  props: {
    filter: {
      type: String as PropType<GrantApplicationStatus>,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const currentFilter = computed({
      set: (filter: string) => {
        router.replace({ query: route.query, params: { filter } });
      },
      get: () => props.filter || GrantApplicationStatus.SentForApproval,
    });

    const facilitationGrantMap =
      ref<Partial<Record<GrantApplicationStatus, ApiGetFacilitationGrantApplicationDto[]>>>();

    const headers = [
      {
        text: "Kurs-ID",
        value: "courseId",
      },
      {
        text: "Status",
        value: "applicationStatus",
      },
      {
        text: "Kursansvarlig",
        value: "courseSupervisorName",
      },
      {
        text: "Avdelingsnavn",
        value: "departmentName",
      },
      { text: "Handlinger", value: "actions" },
    ];

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        facilitationGrantMap.value = (await api.course.getFilteredGrantApplicationsAsync()).data.reduce<
          Partial<Record<GrantApplicationStatus, ApiGetFacilitationGrantApplicationDto[]>>
        >((acc, cur) => {
          if (!cur.applicationStatus) {
            return acc;
          }
          return {
            ...acc,
            [cur.applicationStatus]: [...(acc[cur.applicationStatus as GrantApplicationStatus] ?? []), cur],
          };
        }, {});
      });
    });

    const navigateToDashboard = () => useNavigateBack(DashboardRouteNames.Home);

    const getFacilitationGrantLink = (courseId: string) => ({
      name: SingleCourseRouteNames.CourseFacilitationGrants,
      params: {
        id: courseId,
      },
    });

    const tabs = computed(() => [
      { icon: "mdi-clipboard-text-clock", filter: GrantApplicationStatus.SentForApproval, label: "Søknader" },
      { icon: "mdi-cash", filter: GrantApplicationStatus.CostAdded, label: "Kostnader lagt inn" },
    ]);

    return {
      currentFilter,
      navigateToDashboard,
      facilitationGrantMap,
      formatRelative,
      getFacilitationGrantLink,
      grantApplicationStatusMap,
      headers,
      tabs,
    };
  },
});
